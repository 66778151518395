import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Container,
  Table,
  Alert,
  Button,
} from "react-bootstrap";
import {
  fetchLeaderboard,
  fetchLeagueGuesses,
} from "../services/supabaseService";
import { useQuery } from "@tanstack/react-query";
import { LinkContainer } from "react-router-bootstrap";

const LeaderboardComponent = ({ leaguesData, leagueId }) => {
  const [selectedWeek, setSelectedWeek] = useState(19);
  const [selectedLeague, setSelectedLeague] = useState(leagueId);
  const {
    data: leaderboardData,
    isLoading: leaderboardLoading,
    error: leaderboardError,
  } = useQuery({
    queryKey: ["leaderboard", selectedLeague],
    queryFn: () => fetchLeaderboard(selectedLeague),
    enabled: !!selectedLeague,
    staleTime: 1000 * 60 * 5,
  });
  const {
    data: combinedData,
    isLoading: combinedLoading,
    error: combinedError,
  } = useQuery({
    queryKey: ["combined", selectedLeague],
    queryFn: () => fetchLeagueGuesses(selectedLeague),
    enabled: !!selectedLeague,
    staleTime: 1000 * 60 * 5,
  });
  const filteredCombined = React.useMemo(() => {
    if (!combinedData) {
      return [];
    }

    return combinedData
      .filter((entry) => Number(entry.gameweek) === Number(selectedWeek))
      .sort((a, b) => a.kickofftime - b.kickofftime);
  }, [combinedData, selectedWeek]);

  if (leaderboardLoading || combinedLoading) {
    return <p>Loading...</p>;
  }

  if (leaderboardError || combinedError) {
    return (
      <Container>
        <Alert variant="danger">
          Error: {leaderboardError?.message || combinedError?.message}
        </Alert>
      </Container>
    );
  }

  if (leaguesData.length === 0) {
    return (
      <Container>
        <Alert variant="warning">
          You need to join a league to see the leaderboard!
        </Alert>
      </Container>
    );
  }

  const players = leaderboardData.map((player) => [
    player.player_id,
    player.name || "Unknown Player",
  ]);

  const games = Array.from(
    new Set(filteredCombined.map((entry) => entry.game_id))
  );

  const getPlayerGuess = (gameId, playerId) => {
    const guess = filteredCombined.find(
      (entry) => entry.game_id === gameId && entry.player_id === playerId
    );
    return guess ? guess.guessed_winner : null;
  };

  const getGameWinner = (gameId) => {
    const game = filteredCombined.find((entry) => entry.game_id === gameId);
    return game?.winner || null;
  };

  const getCellIcon = (guessedWinner, actualWinner) => {
    if (!guessedWinner) return "⬜";
    if (!actualWinner) return "❔";
    return guessedWinner === actualWinner ? "✅" : "❌";
  };

  const handleSelectLeague = (leagueId) => {
    setSelectedLeague(leagueId);
  };

  const handleSelectWeek = (week) => {
    setSelectedWeek(Number(week));
  };

  return (
    <Container>
      <DropdownButton
        id="dropdown-basic-button"
        title={`League: ${
          selectedLeague
            ? leaguesData.find((league) => league.id === selectedLeague)?.name
            : "Select a League"
        }`}
        onSelect={handleSelectLeague}
        className="mb-3"
      >
        {leaguesData.map((league) => (
          <LinkContainer to={`/leaderboard/${league.id}`} key={league.id}>
            <Dropdown.Item key={league.id} eventKey={league.id}>
              {league.name}
            </Dropdown.Item>
          </LinkContainer>
        ))}
      </DropdownButton>

      <h2>Leaderboard</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Player Name</th>
            <th>Guess Score</th>
            <th>Season Guess Score</th>
            <th>Total Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((player) => (
            <tr key={player.player_id}>
              <td>{player?.name || "Unknown Player"}</td>
              <td>{player.points}</td>
              <td>{player.season_guess_points}</td>
              <td>{player.points + player.season_guess_points}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3>Game Guesses</h3>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          disabled={combinedLoading || leaderboardLoading}
          variant="primary"
          onClick={() => handleSelectWeek(selectedWeek - 1)}
        >
          ⬅️
        </Button>
        <DropdownButton
          id="dropdown-week-button"
          title={`Select Week: ${selectedWeek}`}
          onSelect={handleSelectWeek}
          className="mb-3"
        >
          {[...Array(18).keys()].map((week) => (
            <Dropdown.Item key={week + 1} eventKey={week + 1}>
              Week {week + 1}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Button
          disabled={combinedLoading || leaderboardLoading}
          variant="primary"
          onClick={() => handleSelectWeek(selectedWeek + 1)}
        >
          ➡️
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Game</th>
            {players.map(([playerId, playerName]) => (
              <th key={playerId}>{playerName}</th>
            ))}
            <th>Winner</th>
          </tr>
        </thead>
        <tbody>
          {games.map((gameId) => {
            const gameData = filteredCombined.find(
              (entry) => entry.game_id === gameId
            ) || {
              team1: "Team 1",
              team2: "Team 2",
              team1_score: 0,
              team2_score: 0,
            }; // Default if no data
            const gameWinner = getGameWinner(gameId);

            return (
              <tr key={gameId}>
                <td>
                  {gameData.team1} {gameData.team1_score} vs.{" "}
                  {gameData.team2_score} {gameData.team2}
                </td>
                {players.map(([playerId]) => {
                  const playerGuess = getPlayerGuess(gameId, playerId);
                  return (
                    <td key={playerId}>
                      {getCellIcon(playerGuess, gameWinner)}
                    </td>
                  );
                })}
                <td>
                  {gameWinner === "1"
                    ? gameData.team1
                    : gameWinner === "2"
                    ? gameData.team2
                    : "❔"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default LeaderboardComponent;
