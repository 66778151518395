import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { supabase } from "./supabaseClient";

// Import your custom components
import AuthPage from "./pages/AuthPage";
import ForgotPassword from "./pages/ForgotPassword";
import UpdatePassword from "./pages/UpdatePassword";
import Register from "./pages/Register";
import Navbar from "./components/Navbar";
import HomeScreen from "./pages/HomeScreen";
import Leaderboard from "./pages/Leaderboard";
import GameGuesses from "./pages/GameGuesses";
import LeagueManagement from "./pages/LeagueManagement";
import SeasonGuesses from "./pages/SeasonGuesses";
import UserManagement from "./pages/UserManagement";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

export default function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false); // Set loading to false once session is resolved
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Analytics />
      <SpeedInsights />
      {session && <Navbar />}
      <Routes>
        {/* Public Routes */}
        <Route
          path="/auth"
          element={session ? <Navigate to="/" /> : <AuthPage />}
        />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/update-password" element={<UpdatePassword />} />

        {/* Protected Routes */}
        {session ? (
          <>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/leaderboard/:leagueId" element={<Leaderboard />} />
            <Route path="/games" element={<GameGuesses />} />
            <Route path="/league" element={<LeagueManagement />} />
            <Route path="/SeasonGuesses" element={<SeasonGuesses />} />
            <Route path="/UserManagement" element={<UserManagement />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
