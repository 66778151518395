import React, { useState } from "react";
import { Container, Alert, ButtonGroup, Button } from "react-bootstrap";
import GameCard from "../components/GameCard";
import FinishedGameCard from "../components/FinishedGameCard";
import {
  fetchGamesByWeek,
  fetchUserId,
  fetchGuessesByUser,
  handleUserGuess,
} from "../services/supabaseService";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const GameGuesses = () => {
  const queryClient = useQueryClient();
  const [week, setWeek] = useState(20);
  const weekSelectionBreak = 10;

  // Fetch user information
  const {
    data: user,
    error: userError,
    isLoading: userLoading,
  } = useQuery({
    queryKey: ["user"],
    queryFn: fetchUserId,
  });

  // Fetch games for the selected week
  const {
    data: gamesData,
    error: gamesError,
    isLoading: gamesLoading,
  } = useQuery({
    queryKey: ["games", week],
    queryFn: () => fetchGamesByWeek(week),
  });

  // Fetch user guesses
  const {
    data: guessesData,
    error: guessesError,
    isLoading: guessesLoading,
  } = useQuery({
    queryKey: ["guesses", user],
    queryFn: () => fetchGuessesByUser(user),
    enabled: !!user,
  });

  // Prepare guesses mapping
  const guesses = React.useMemo(() => {
    const guessMap = {};
    if (guessesData) {
      guessesData.forEach((guess) => {
        guessMap[guess.game_id] = guess.guessed_winner;
      });
    }
    return guessMap;
  }, [guessesData]);

  // Sort games by kickoff time
  const sortedGames = React.useMemo(() => {
    return gamesData
      ? [...gamesData].sort(
          (a, b) => new Date(a.kickofftime) - new Date(b.kickofftime)
        )
      : [];
  }, [gamesData]);

  // Mutation for handling user guesses
  const mutation = useMutation({
    mutationFn: ({ gameId, team }) => handleUserGuess(gameId, team, user),
    onSuccess: () => {
      // Invalidate and refetch guesses
      queryClient.invalidateQueries({ queryKey: ["guesses", user] });
    },
  });

  const handleGuess = (gameId, team) => {
    if (!user) {
      alert("Please log in to make a guess.");
      return;
    }
    mutation.mutate({ gameId, team });
  };

  const handleWeekChange = (newWeek) => {
    setWeek(newWeek);
  };

  // Handle loading and error states
  if (userLoading || gamesLoading || guessesLoading) {
    return <div>Loading...</div>;
  }

  const error = userError || gamesError || guessesError || mutation.error;

  return (
    <Container className="mt-4">
      <h1 className="mb-4">Games for Week 🏈 {week}</h1>
      {/* Game Week Selection */}
      <ButtonGroup className="w-100 mb-3">
        {Array.from({ length: 9 }, (_, i) => (
          <Button
            key={i + 1}
            variant={week === i + 1 ? "primary" : "outline-primary"}
            onClick={() => handleWeekChange(i + 1)}
          >
            Week {i + 1}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup className="w-100 mb-3">
        {Array.from({ length: 9 }, (_, i) => (
          <Button
            key={i + weekSelectionBreak}
            variant={
              week === i + weekSelectionBreak ? "primary" : "outline-primary"
            }
            onClick={() => handleWeekChange(i + weekSelectionBreak)}
          >
            Week {i + weekSelectionBreak}
          </Button>
        ))}
      </ButtonGroup>

      <ButtonGroup className="w-100 mb-3">
        <Button
          key={19}
          variant={week === 19 ? "primary" : "outline-primary"}
          onClick={() => handleWeekChange(19)}
        >
          Wildcard
        </Button>
        <Button
          key={20}
          variant={week === 20 ? "primary" : "outline-primary"}
          onClick={() => handleWeekChange(20)}
        >
          Divisional Playoffs
        </Button>
        <Button
          key={21}
          variant={week === 21 ? "primary" : "outline-primary"}
          onClick={() => handleWeekChange(21)}
        >
          Conference Championships
        </Button>
        <Button
          key={22}
          variant={week === 22 ? "primary" : "outline-primary"}
          onClick={() => handleWeekChange(22)}
        >
          Superbowl
        </Button>
      </ButtonGroup>

      {error && <Alert variant="danger">Error: {error.message}</Alert>}
      {sortedGames.map((game) =>
        game.winner ? (
          <FinishedGameCard
            key={game.id}
            game={game}
            user={user}
            guessedTeam={guesses[game.id]}
            winner={game.winner}
          />
        ) : (
          <GameCard
            key={game.id}
            game={game}
            user={user}
            guessedTeam={guesses[game.id]}
            onGuess={handleGuess}
          />
        )
      )}
    </Container>
  );
};

export default GameGuesses;
