import { supabase } from "../supabaseClient";
import { API_BASE_URL } from "../apiconfig";

export const updatePlayerName = async (playerId, newName) => {
  try {
    const response = await fetch(`${API_BASE_URL}/updatePlayerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ playerId, newName }),
    });

    const result = await response.json();

    if (!response.ok) {
      console.error("Error updating player name:", result.error);
      return { success: false, error: result.error };
    }

    return { success: true, data: result.data };
  } catch (error) {
    console.error("Unexpected error updating player name:", error);
    return { success: false, error };
  }
};
// Fetch games for a specific game week
export const fetchGamesByWeek = async (week) => {
  try {
    let { data, error } = await supabase
      .from("games")
      .select("id, team1, team2, kickofftime, winner, team1_score, team2_score")
      .eq("gameweek", week);

    if (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchSeasonGuessesWinners = async () => {
  try {
    const { data, error } = await supabase
      .from("season_bet_results")
      .select("*");
    if (error) {
      console.error("Error fetching season guesses winners:", error);
      throw error;
    }
    return data[0];
  } catch (error) {
    throw error;
  }
};

export const fetchAllGames = async () => {
  try {
    let { data, error } = await supabase

      .from("games")
      .select(
        "id, team1, team2, gameweek, winner, team1_score, team2_score, kickofftime"
      );
    if (error) {
      console.error("Error fetching games:", error);
      throw error;
    }

    return data;
  } catch (error) {
    throw error;
  }
};
export const fetchUserId = async () => {
  try {
    const { data: user, error } = await supabase.auth.getUser();
    if (error) {
      console.error("Error fetching user:", error);
      throw error;
    }

    return user.user.id;
  } catch (error) {
    console.error("Error fetching user ID:", error);
    throw error;
  }
};
export const fetchCurrentUser = async () => {
  try {
    // Fetch authenticated user data from Supabase
    const { data: authData, error: authError } = await supabase.auth.getUser();

    if (authError) {
      throw authError;
    }

    if (!authData?.user?.id) {
      console.error("User is not authenticated or user ID is missing.");
      return null;
    }

    // Fetch user information from the players table
    const { data: playerData, error: playerError } = await supabase
      .from("players")
      .select("name")
      .eq("id", authData.user.id); // Use single() since we expect exactly one result

    if (playerError) {
      console.error("Error fetching player data:", playerError);
      if (playerError.code === "PGRST116") {
        console.warn(
          `No player found with ID: ${authData.user.id}. Ensure the player exists in the 'players' table.`
        );
      }
      throw playerError;
    }

    // Log the player's name and ID

    // Return the user ID and name
    return { id: authData.user.id, name: playerData[0].name };
  } catch (error) {
    console.error("Error in fetchCurrentUser:", error);
    throw error;
  }
};

// Fetch guesses for the current user
export const fetchGuessesByUser = async (userId) => {
  try {
    const { data, error } = await supabase
      .from("guesses")
      .select("game_id, guessed_winner")
      .eq("player_id", userId);

    if (error) {
      console.error("Error fetching guesses:", error);
      throw error;
    }

    return data;
  } catch (error) {
    throw error;
  }
};

// Handle user guess
export const handleUserGuess = async (gameId, team, userId) => {
  try {
    const { data, error } = await supabase
      .from("guesses")
      .upsert(
        [{ game_id: gameId, guessed_winner: team, player_id: userId }],
        { onConflict: ["player_id", "game_id"] } // Ensure upsert is based on player_id and game_id
      )
      .single();

    if (error) {
      console.error("Error making guess:", error);
      throw error;
    }

    return data;
  } catch (error) {
    throw error;
  }
};
// Fetch leagues the user is in
export const fetchUserLeagues = async () => {
  try {
    const user = await supabase.auth.getUser();
    const { data, error } = await supabase
      .from("league_players")
      .select("league_id")
      .eq("player_id", user.data.user.id);

    if (error) throw error;

    // Return list of leagues
    return data.map((item) => item.league_id);
  } catch (error) {
    console.error("Error fetching user leagues:", error);
    return [];
  }
};

export const fetchLeagueNamesByIds = async (leagueIds) => {
  try {
    const { data, error } = await supabase
      .from("league")
      .select("id, name")
      .in("id", leagueIds);

    if (error) throw error;

    // Return list of league name/id pairs
    return data.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  } catch (error) {
    console.error("Error fetching league names:", error);
    return [];
  }
};

export const fetchUserLeaguesWithNames = async () => {
  try {
    // Fetch the user's league IDs
    const leagueIds = await fetchUserLeagues();

    if (leagueIds.length === 0) return [];

    // Fetch the league names based on IDs
    const leaguesWithNames = await fetchLeagueNamesByIds(leagueIds);
    return leaguesWithNames;
  } catch (error) {
    console.error("Error fetching user leagues with names:", error);
    return [];
  }
};

export const fetchPlayersInLeague = async (leagueId) => {
  try {
    const { data, error } = await supabase
      .from("league_players")
      .select("players(id, name)")
      .eq("league_id", leagueId);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error("Error fetching players in league:", error);
    return [];
  }
};
// Fetch leaderboard data
export const fetchLeaderboard = async (leagueId) => {
  try {
    let playerIds = [];
    let playersMap = new Map(); // Use a Map to associate player_id with player_name

    if (leagueId) {
      // Fetch players for the specific league
      const { data: leaguePlayers, error: leaguePlayersError } = await supabase
        .from("league_players")
        .select("player_id, players(name)")
        .eq("league_id", leagueId);

      if (leaguePlayersError) throw leaguePlayersError;

      // Create a Map to easily find player_name by player_id
      leaguePlayers.forEach((player) => {
        playersMap.set(player.player_id, player.players.name);
      });

      // Extract player IDs to use in the leaderboard query
      playerIds = leaguePlayers.map((item) => item.player_id);
    }

    // If no players are found, return an empty array
    if (playerIds.length === 0) {
      return [];
    }
    // Fetch leaderboard data for the specific league players
    const { data: leaderboardData, error: leaderboardError } = await supabase
      .from("leaderboards")
      .select("player_id, points, total_points, season_guess_points")
      .in("player_id", playerIds)
      .order("total_points", { ascending: false });
    if (leaderboardError) {
      console.error("Error fetching leaderboard data:", leaderboardError);
      return [];
    }
    // Merge player names with leaderboard data
    const leaderboardWithNames = leaderboardData.map((entry) => ({
      player_id: entry.player_id,
      points: entry.points,
      name: playersMap.get(entry.player_id) || "Unknown Player",
      total_point: entry.total_points,
      season_guess_points: entry.season_guess_points,
      // Use the Map to get player_name by player_id
    }));
    return leaderboardWithNames;
  } catch (error) {
    console.error("Error fetching leaderboard:", error);
    return [];
  }
};

// Create a new league and add the user to it
export const createLeague = async (leagueName) => {
  try {
    // Get the current authenticated user
    const { data: user, error: userError } = await supabase.auth.getUser();

    if (userError) {
      console.error("Error fetching user:", userError);
      throw userError;
    }

    // Generate a unique invite code
    const inviteCode = generateInviteCode();

    // Insert a new league
    const { data: league, error: leagueError } = await supabase
      .from("league")
      .insert([{ name: leagueName, invite_code: inviteCode }])
      .single();

    if (leagueError) {
      console.error("Error creating league:", leagueError);
      throw leagueError;
    }

    // Use joinLeague to add the user to the new league
    await joinLeague(inviteCode, user.id); // Pass the invite code and user ID to joinLeague

    return { inviteCode }; // Return the generated invite code
  } catch (error) {
    console.error("Unexpected error creating league:", error);
    throw error;
  }
};

// Join an existing league using invite code
export const joinLeague = async (inviteCode, playerId = null) => {
  try {
    // Fetch the current user if playerId is not provided
    if (!playerId) {
      const { data: user, error: userError } = await supabase.auth.getUser();

      if (userError) {
        console.error("Error fetching user:", userError);
        throw userError;
      }
      playerId = user.user.id;
    }

    // Fetch the league ID using the invite code
    const { data: leagueData, error: leagueError } = await supabase
      .from("league")
      .select("id")
      .eq("invite_code", inviteCode)
      .single();

    if (leagueError) {
      console.error("Error fetching league:", leagueError);
      throw leagueError;
    }

    // Insert a new entry into the league_players table to add the player to the league
    const { error: joinError } = await supabase.from("league_players").insert([
      {
        league_id: leagueData.id,
        player_id: playerId,
      },
    ]);

    if (joinError) {
      console.error("Error joining league:", joinError);
      throw joinError;
    }

    return leagueData;
  } catch (error) {
    console.error("Unexpected error joining league:", error);
    throw error;
  }
};

export const handleLogOut = async () => {
  try {
    await supabase.auth.signOut();
  } catch (error) {
    console.error("Error logging out:", error);
    throw error;
  }
};

// Fetch player IDs for a specific league
export const fetchLeaguePlayers = async (leagueId) => {
  try {
    const { data, error } = await supabase
      .from("league_players")
      .select("player_id, player_name")
      .eq("league_id", leagueId);

    if (error) throw error;

    // Return the list of player IDs
    return data.map((item) => item.player_id);
  } catch (error) {
    console.error("Error fetching league players:", error);
    return [];
  }
};

export const getLeagues = async (userId) => {
  try {
    const { data, error } = await supabase
      .from("league_players")
      .select("league_id")
      .eq("player_id", userId);

    if (error) {
      console.error("Error fetching leagues:", error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Unexpected error fetching leagues:", error);
    throw error;
  }
};

const generateInviteCode = (length = 6) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
// Function to handle season guesses (insert or update)
export const handleSeasonGuesses = async (guesses) => {
  try {
    // Fetch the authenticated user's ID
    const {
      data: { user },
      error: authError,
    } = await supabase.auth.getUser();

    if (authError) {
      console.error("Error fetching authenticated user:", authError);
      return { success: false, message: "Error fetching authenticated user." };
    }

    if (!user) {
      return { success: false, message: "User not authenticated." };
    }

    // Include user ID in the guesses object
    const player_id = user.id;

    const {
      afc_north_winner,
      afc_east_winner,
      afc_west_winner,
      afc_south_winner,
      nfc_north_winner,
      nfc_east_winner,
      nfc_west_winner,
      nfc_south_winner,
      superbowl_winner,
      mvp,
      rookie_of_the_year,
      most_throwing_yards,
      most_receiving_yards,
      most_running_yards,
      afc_1st_seed,
      nfc_1st_seed,
    } = guesses;

    // Check if an entry for the user already exists
    const { data: existingGuesses, error: fetchError } = await supabase
      .from("season_bets")
      .select("id")
      .eq("player_id", player_id)
      .single();

    if (fetchError && fetchError.code !== "PGRST116") {
      // Error code 'PGRST116' means no rows found, ignore it
      console.error("Error checking existing guesses:", fetchError);
      return { success: false, message: "Error checking existing guesses." };
    }

    // If entry exists, update it
    if (existingGuesses) {
      const { error: updateError } = await supabase
        .from("season_bets")
        .update({
          afc_north_winner: afc_north_winner || null,
          afc_east_winner: afc_east_winner || null,
          afc_west_winner: afc_west_winner || null,
          afc_south_winner: afc_south_winner || null,
          nfc_north_winner: nfc_north_winner || null,
          nfc_east_winner: nfc_east_winner || null,
          nfc_west_winner: nfc_west_winner || null,
          nfc_south_winner: nfc_south_winner || null,
          superbowl_winner: superbowl_winner || null,
          mvp: mvp || null,
          rookie_of_the_year: rookie_of_the_year || null,
          most_throwing_yards: most_throwing_yards || null,
          most_receiving_yards: most_receiving_yards || null,
          most_running_yards: most_running_yards || null,
          afc_1st_seed: afc_1st_seed || null,
          nfc_1st_seed: nfc_1st_seed || null,
          updated_at: new Date(),
        })
        .eq("player_id", player_id);

      if (updateError) {
        console.error("Error updating season guesses:", updateError);
        return { success: false, message: "Error updating season guesses." };
      }
      return { success: true, message: "Season guesses updated successfully." };
    } else {
      // If no entry exists, insert a new one
      const { error: insertError } = await supabase.from("season_bets").insert([
        {
          player_id,
          afc_north_winner: afc_north_winner || null,
          afc_east_winner: afc_east_winner || null,
          afc_west_winner: afc_west_winner || null,
          afc_south_winner: afc_south_winner || null,
          nfc_north_winner: nfc_north_winner || null,
          nfc_east_winner: nfc_east_winner || null,
          nfc_west_winner: nfc_west_winner || null,
          nfc_south_winner: nfc_south_winner || null,
          superbowl_winner: superbowl_winner || null,
          mvp: mvp || null,
          rookie_of_the_year: rookie_of_the_year || null,
          most_throwing_yards: most_throwing_yards || null,
          most_receiving_yards: most_receiving_yards || null,
          most_running_yards: most_running_yards || null,
          afc_1st_seed: afc_1st_seed || null,
          nfc_1st_seed: nfc_1st_seed || null,
          created_at: new Date(),
          updated_at: new Date(),
        },
      ]);

      if (insertError) {
        console.error("Error inserting new season guesses:", insertError);
        return {
          success: false,
          message: "Error inserting new season guesses.",
        };
      }
      return {
        success: true,
        message: "Season guesses inserted successfully.",
      };
    }
  } catch (error) {
    console.error("Unexpected error handling season guesses:", error);
    return {
      success: false,
      message: "Unexpected error handling season guesses.",
    };
  }
};

// Function to fetch current guesses for the authenticated user
export const fetchCurrentGuesses = async () => {
  try {
    // Fetch the authenticated user's ID
    const {
      data: { user },
      error: authError,
    } = await supabase.auth.getUser();

    if (authError) {
      console.error("Error fetching authenticated user:", authError);
      return { success: false, message: "Error fetching authenticated user." };
    }

    if (!user) {
      return { success: false, message: "User not authenticated." };
    }

    // Fetch the current guesses for the user
    const { data: currentGuesses, error } = await supabase
      .from("season_bets")
      .select("*")
      .eq("player_id", user.id)
      .single();

    if (error && error.code !== "PGRST116") {
      // Error code 'PGRST116' means no rows found, ignore it
      console.error("Error fetching current guesses:", error);
      return { success: false, message: "Error fetching current guesses." };
    }

    return { success: true, data: currentGuesses || null };
  } catch (error) {
    console.error("Unexpected error fetching current guesses:", error);
    return {
      success: false,
      message: "Unexpected error fetching current guesses.",
    };
  }
};

export const fetchLeagueGuesses = async (leagueId) => {
  try {
    const players = await fetchPlayersInLeague(leagueId);
    const playerIds = players.map((player) => player.players.id);
    const games = await fetchAllGames();

    const { data, error } = await supabase
      .from("guesses")
      .select("player_id, game_id, guessed_winner, id")
      .in("player_id", playerIds);
    // create a combined array from data and games, they should share the same id
    const combined = data.map((item) => {
      const game = games.find((game) => game.id === item.game_id);
      const playerName = players.find(
        (player) => player.players.id === item.player_id
      ).players.name;

      return { ...item, ...game, playerName };
    });
    const sorted = combined.sort((a, b) => a.kickofftime - b.kickofftime);
    if (error) {
      console.error("Error fetching weekly guesses:", error);
      return [];
    }

    return sorted;
  } catch (error) {
    console.error("Unexpected error fetching weekly guesses:", error);
    return [];
  }
};

export const fetchSeasonGuessesForLeague = async (leagueId) => {
  try {
    // Step 1: Fetch players in the league
    const players = await fetchPlayersInLeague(leagueId);
    const playerIds = players.map((player) => player.players.id);

    // Step 2: Fetch season guesses for each player in the league
    const { data: seasonGuesses, error } = await supabase
      .from("season_bets")
      .select("*")
      .in("player_id", playerIds);

    if (error) {
      console.error("Error fetching season guesses for league:", error);
      return [];
    }

    // Step 3: Combine player names with guesses data
    const combinedData = seasonGuesses.map((guess) => {
      const player = players.find(
        (player) => player.players.id === guess.player_id
      );
      return {
        ...guess,
        playerName: player.players.name || "Unknown Player",
      };
    });

    return combinedData;
  } catch (error) {
    console.error(
      "Unexpected error fetching season guesses for league:",
      error
    );
    return [];
  }
};

export const fetchUserLeaguesAndRank = async () => {
  try {
    // Fetch the user's league IDs
    const leagueIds = await fetchUserLeagues();
    const userId = await fetchUserId();

    if (leagueIds.length === 0) return [];

    // Fetch the league names based on IDs
    const leaguesWithNames = await fetchLeagueNamesByIds(leagueIds);

    // Fetch leaderboard data for each league
    const leaderboardData = await Promise.all(
      leaguesWithNames.map(async (league) => {
        const leaderboard = await fetchLeaderboard(league.id);
        const rank = leaderboard.findIndex(
          (player) => player.player_id === userId
        );
        return {
          leagueId: league.id,
          leagueName: league.name,
          rank: rank + 1,
        };
      })
    );
    return leaderboardData;
  } catch (error) {
    console.error("Error fetching user leagues with rank:", error);
    return [];
  }
};
